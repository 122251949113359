import React from "react"
import Layout from "../components/common/layout"
import Meta from "../components/common/meta"
import MainVisual from "../components/pages/top/MainVisual"
// import CardList from "../components/pages/top/CardList"

const IndexPage = () => (
  <Layout page="top">
    <Meta title="a definition of beauty 美の定義 | 1moku co." type="website" />
    <MainVisual />
    <section className="sec sec-copy">
      <div className="sec__inner copy">
        <h2 className="copy__title en" data-scroll="">
          a definition of beauty
        </h2>
        <h2 className="copy__title ja" data-scroll="">
          美の定義
        </h2>
        <p className="copy__description en" data-scroll="">
          Neither nature’s trees nor its flowers <br className="sp" />
          can afford to live sparingly.
          <br />
          They are always living at their fullest, <br className="sp" />
          in a form of perfect balance.
          <br />
          Even so, I do not believe showing <br className="sp" />
          them at their fullest could <br className="sp" />
          be called beautiful.
          <br />
          Rather than watching branches and <br className="sp" />
          flowers bending before a strong wind,
          <br />
          I find a gentle swaying to be <br className="sp" />
          more appealing. <br className="pc" />
          People are similar, <br className="sp" />
          perhaps true strength and empathy are <br className="sp" />
          felt <br className="pc" />
          when seeing the smile of a person <br className="sp" />
          who has struggled and survived.
          <br />
          True quality is not just brandishing <br className="sp" />
          logic and correctness.
          <br />
          Though it might seem lacking, <br className="sp" />I believe it is
          likely something
          <br className="pc" />
          similar <br className="sp" />
          to affection and a contented smile.
        </p>
        <p className="copy__description ja" data-scroll="">
          自然の木々の草花の関係にしても、
          <br className="sp" />
          何一つ余裕で生きてる事はない
          <br />
          全てが自らの極限で成立しているように思われるし
          <br />
          それが最もバランスの取れた状態と言える
          <br />
          そうであっても極限を
          <br className="sp" />
          そのまま表現する事が美しいとは思われない
          <br />
          大風に今にも折れそうな枝や花を見るより、
          <br className="sp" />
          そよ風にゆらぐたおやかさを私は好む
          <br />
          人にしても、必死を通り抜けた清々しい笑顔に
          <br className="sp" />
          むしろ強さを感じられし共鳴もするだろう
          <br />
          上質とは道理や正義を振りかざすものではなく
          <br />
          少し物足りないと思うかも知れないが
          <br />
          いつくしみや、包み込んだ微笑みに
          <br className="sp" />
          似たようなものではないか
        </p>
        <div className="copy__sign">
          <img
            className="pc"
            width="145"
            height="144"
            src="/image/top/sign.png"
            alt="artist/CEO hirofumi suga"
          />
          <img
            className="sp"
            width="249"
            height="230"
            src="/image/top/sp_sign.png"
            alt="artist/CEO hirofumi suga"
          />
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
