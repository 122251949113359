import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const MainVisual = () => {
  const [loadState, setLoadState] = useState("loading")
  const _slider = useRef(null)

  useEffect(() => {
    setTimeout(function() {
      setLoadState("start")
      _slider.current.slickGoTo(1)
    }, 300)
    setTimeout(function() {
      _slider.current.slickGoTo(0)
    }, 4000)
    setTimeout(function() {
      setLoadState("finish")
    }, 4300)
  }, [])

  const numList = [1, 2, 3, 4, 5, 6]
  const slickSettings = {
    arrows: false,
    customPaging: function(slider, i) {
      return <div className="mv-slider__dos-item"></div>
    },
    dotsClass: "mv-slider__dots-list",
    dots: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 600,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    lazyLoad: true,
  }

  return (
    <section id="main-visual" className="sec sec-main-visual">
      <div data-state={loadState} className="main-visual">
        <Slider
          ref={_slider}
          {...slickSettings}
          className="main-visual__bg mv-slider"
        >
          {numList.map((v, index) => {
            return (
              <div
                className={`mv-slider__item mv-slider__item--${v}`}
                key={index}
              ></div>
            )
          })}
        </Slider>
        <div className="main-visual__loading loading">
          <div className="loading__in">
            <div className="loading__contain">
              <div className="loading__logo">
                <img
                  src="/image/top/main_visual_logo.svg"
                  width="10"
                  height="19"
                  alt="1"
                />
              </div>
              <p className="loading__caption">a definition of beauty</p>
            </div>
          </div>
        </div>
        <div className="main-visual__contain">
          <h1 className="main-visual__title">
            <span className="main-visual__logo">
              <img
                src="/image/top/main_visual_logo.svg"
                width="27"
                height="50"
                alt="1"
              />
            </span>
            <span className="main-visual__sub">
              <span className="main-visual__sub-in">
                moku{" "}
                <span className="main-visual__sub-color">
                  landScape design &amp; reserch
                </span>
              </span>
            </span>
          </h1>
        </div>
        <div className="scroll-bar"></div>
        <p className="scroll-label en">tokyo & barcelona</p>
      </div>
    </section>
  )
}

export default MainVisual
